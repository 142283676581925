import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { AppProvider } from './AppProvider';

let AppQuery = props => {
  let { children } = props;

  return <AppProvider>{children}</AppProvider>;
};

export { AppQuery };
