//let homePagePath = (language) =>  `${language=='en-us'?"":"/"+ language + "/"}`;

var linkResolver = function(doc) {
  // Pretty URLs for known types
  // let lang = doc.lang;
  // const uid = doc.uid;
  
  if (doc.type === 'tour') return `${(doc.lang === "en-us" ? "" : `/${doc.lang}` )}/tours/${doc.uid}`;
  if (doc.type === 'homepage') return (doc.lang === "en-us" ? "" : `/${doc.lang}` );
  if (doc.type === 'homepage') return (doc.lang === "en-us" ? "" : `/${doc.lang}` );
  if (doc.type === 'gov_tours_custom_page') return (doc.lang === "en-us" ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}` );
  // Fallback for other types, in case new custom types get created
  return '/doc/' + doc.id;
};

module.exports.linkResolver = linkResolver;
