import React from 'react';
import { navigate } from 'gatsby';

import { linkResolver } from '../utils/linkResolver';
import AppContext, { defaultState } from './AppContext';

class AppProvider extends React.Component {
  state = {
    ...defaultState
  };

  constructor(props) {
    super(props);
    this.setSortMethod = this.setSortMethod.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let { languages } = props;

    return {
      ...state,
      languages: languages
    };
  }

  updateSlickSettings = settings => {
    this.setState({ settings: settings });
  };

  clearFilters = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(function(check) {
      if (check.checked) {
        check.checked = false;
      }
    });

    this.setState({
      activeFilters: []
    });
  };

  updatePlaylist = playlist => {
    this.setState({ playlist: playlist });
  };

  setSortMethod = sortMethod => {
    this.setState({
      sortMethod: sortMethod,
      showSort: false,
      showFilters: false,
      hideHeader: false
    });
  };

  toggleShowSort = () => {
    this.setState({
      showSort: !this.state.showSort,
      hideHeader: !this.state.hideHeader
    });
  };

  toggleShowFilters = () => {
    const { showFilters } = this.state;
    if (!showFilters) {
      this.setState({
        showFilters: true,
        hideHeader: true
      });
    } else {
      this.setState({
        showFilters: false,
        hideHeader: false
      });
    }
  };

  toggleMobileSortAndFilter = () => {
    const { showFilters, showSort } = this.state;
    if (!showFilters || !showSort) {
      this.setState({
        showFilters: true,
        showSort: true,
        hideHeader: true
      });
    } else {
      this.setState({
        showFilters: false,
        showSort: false,
        hideHeader: false
      });
    }
  };

  closeOverlay = () => {
    this.setState({
      showFilters: false,
      showSort: false,
      hideHeader: false
    });
  };

  toggleDraggablePlaylist = linkName => {
    const { showPlaylist, playlist } = this.state;
    if (playlist.length > 1) {
      if (showPlaylist) {
        this.setState({
          showPlaylist: false
        });
      } else {
        this.setState({
          showPlaylist: true
        });
      }
    }

    if (linkName) {
      if (linkName.tour_title[0].text) {
        this.setCurrentTourTitle(linkName.tour_title[0].text);
      }
    } else {
      this.clearUndefinedPlaylist();
    }
  };

  // edge case: When a user hard refreshes on a tour, if the user clicks in the sortable playlist and clicks the tour,
  // it updates the playlist to have one array value of undefined
  // if the user clicks the logo and goes back to the home page
  // the user cannot add to their playlist
  // this will set the playlist back to an empty array
  clearUndefinedPlaylist = () => {
    if (this.state.playlist[0] === undefined) {
      this.setState({
        playlist: []
      });
    }
  };

  clearPlaylistFromTour = location => {
    if (location !== 'home') {
      this.setState({
        playlist: []
      });
    }
  };

  setCurrentTourTitle = tourName => {
    if (this.state.currentTourTitle === '' || this.state.currentTourTitle !== tourName) {
      this.setState({
        currentTourTitle: tourName
      });
    }
  };

  updateTourPlaylist = newTourArray => {
    this.setState({
      playlist: newTourArray
    });
  };

  checkCurrentTourTitle = (currentTourTitle, playlist) => {
    // if a user hard refreshes on a tour, the list is erased, this will set the tour title to the current title
    if (currentTourTitle === '' && playlist.length === 0) {
      let pathNameArray = window.location.pathname.split('/');
      let tourTitle = pathNameArray[2].split('-');
      let capitalizedTitle = [];
      tourTitle.map((name, index) => {
        // capitalize each first letter in the title name
        let capitalNames = name.charAt(0).toUpperCase() + name.slice(1);
        return capitalizedTitle.push(capitalNames);
      });
      // join the array together to make a string
      capitalizedTitle = capitalizedTitle.join(' ');
      // set the currentTitleTour to the capitalized Title
      this.setState({
        currentTourTitle: capitalizedTitle
      });
    } else {
      // check to see if there is a current title and set the currentTitle state
      if (currentTourTitle && this.state.currentTourTitle === '') {
        this.setState({
          currentTourTitle
        });
      }
    }
    return currentTourTitle;
  };

  getCurrentSlide = slideIndex => {
    if (this.state.currentSlide !== slideIndex) {
      this.setState({
        currentSlide: slideIndex
      });
    }
  };

  updateDefaultTimeInterval = e => {
    let newTimerInterval = parseInt(e.target.innerText);
    if (this.state.defaultTimeInterval !== newTimerInterval && newTimerInterval !== NaN) {
      this.setState({
        defaultTimeInterval: newTimerInterval * 1000,
        showTimeIntervals: false,
        settings: { ...this.state.settings, autoplaySpeed: newTimerInterval * 1000 }
      });
    } else {
      return false;
    }
  };

  toggleTimerIntervals = () => {
    if (this.state.showTimeIntervals) {
      this.setState({
        showTimeIntervals: false
      });
    } else {
      this.setState({
        showTimeIntervals: true
      });
    }
  };

  togglePlayPauseButton = () => {
    if (!this.state.presentationMode) {
      this.setState({
        showPlayButton: false,
        timerInitiated: true,
        presentationMode: true
      });
    } else {
      this.setState({
        showPlayButton: true,
        timerInitiated: false,
        presentationMode: false
      });
    }
  };

  startPresentationMode = () => {
    this.setState({
      presentationMode: true
    });
  };
  stopPresentationMode = () => {
    this.setState({
      presentationMode: false
    });
  };

  stopTourAutoPlay = () => {
    // always reset play and timer buttons when next or previous is pressed
    this.setState({
      showPlayButton: true,
      timerInitiated: false
    });
  };

  stopPresentationMode = () => {
    this.setState({
      presentationMode: false
    });
  };

  updateLanguage = language => {
    if (this.state.lang !== language.lang) {
      this.setState({
        lang: language.lang
      });
      navigate(language.lang === 'en-us' ? '/' : `/${language.lang}`);
    }
  };

  checkSlideIndexNext = (app, backLink, currentTourTitle) => {
    // on custom page tours with playlist the app.currentTourTitle is undefined
    // if so use the currentTourTitle being passed in
    let thisCurrentTourTitle = app.currentTourTitle === undefined ? currentTourTitle : app.currentTourTitle;

    if (app.playlist.length > 0) {
      let currentTour = app.playlist.filter(tour => tour.tour_title[0].text === thisCurrentTourTitle);
      let tourIndex = app.playlist.findIndex(tour => tour.tour_title[0].text === thisCurrentTourTitle);

      let thisTourSteps = currentTour[0].steps.length;

      let nextTour = app.playlist[tourIndex + 1];
      // if nextTour is undefined (no additional playlist)
      // setPlaylist back to the first one
      if (nextTour === undefined) {
        nextTour = app.playlist[0];
      }

      if (app.currentSlide === thisTourSteps && this.state.currentSlide === app.currentSlide) {
        if (nextTour !== undefined) {
          let nextTourTitle = nextTour.tour_title[0].text;
          // create link
          // if backLink is undefined make empty string or url will not work
          if (backLink === undefined) {
            backLink = '';
          }
          let url = backLink === '' ? linkResolver(nextTour._meta) : linkResolver(nextTour._meta) + backLink;

          navigate(url);
          this.setCurrentTourTitle(nextTourTitle);
          this.setState({
            showPlayButton: true
          });
        }
      }
    }
  };

  checkSlideIndexPrev = (app, backLink) => {
    if (app.playlist.length > 1) {
      let tourIndex = app.playlist.findIndex(tour => tour.tour_title[0].text === app.currentTourTitle);

      let prevTour = app.playlist[tourIndex - 1];
      // if at the start of the tour, prevTour is undefined
      // set to the last tour in the playlist
      if (prevTour === undefined) {
        prevTour = app.playlist[app.playlist.length - 1];
      }

      // if the user clicked back and the current slide is on and there is a previous tour, go back to the previous tour
      if (app.currentSlide === 1 && prevTour) {
        let prevTourTitle = prevTour.tour_title[0].text;
        // create link
        let url = backLink === '' ? linkResolver(prevTour._meta) : linkResolver(prevTour._meta) + backLink;
        // send the browser to the next tour
        navigate(url);
        this.setCurrentTourTitle(prevTourTitle);
      }
    }
  };

  setShowPlayButton = () => {
    this.setState({
      showPlayButton: true
    });
  };

  handleKeyPress = (e, location) => {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    let tourNextButton = document.querySelector('.tour-next-btn');
    let tourPrevButton = document.querySelector('.tour-previous-btn');
    // only activate key press on tour page
    if (location === 'tour') {
      if (e.keyCode === LEFT_ARROW && tourPrevButton) {
        tourPrevButton.click();
      }
      if (e.keyCode === RIGHT_ARROW && tourNextButton) {
        tourNextButton.click();
      }
    }
  };

  toggleLanguageVisible = () => {
    this.setState({ languageVisible: !this.state.languageVisible });
  };

  addMobileClass = target => {
    let btnElement = target.parentElement.parentElement;
    if (btnElement.classList.contains('mobile-off')) {
      btnElement.classList.remove('mobile-off');
    }
    btnElement.classList.add('mobile');
  };

  removeMobileClass = target => {
    let btnElement = target.parentElement.parentElement;
    // give a small delay so that the user can the button change
    setTimeout(() => btnElement.classList.add('mobile-off'), 10);
  };

  closeOpenDialogs = location => {
    if (location === 'tour' && this.state.showPlaylist) {
      this.setState({
        // showPlaylist: false
      });
    }
  };

  goToTour = tourMeta => {
    let url = linkResolver(tourMeta);
    navigate(url);
  };

  toggleFilter = filter => {
    let { activeFilters } = this.state;
    let activeFiltersContainFilter = activeFilters && activeFilters.length && activeFilters.includes(filter);

    if (activeFiltersContainFilter) {
      let activeFiltersWithoutFilter = activeFilters.filter(af => af !== filter);
      this.setState({ activeFilters: activeFiltersWithoutFilter });
    } else {
      let activeFiltersWithFilter = [...this.state.activeFilters, filter];
      this.setState({ activeFilters: activeFiltersWithFilter });
    }
  };

  updatePlaylist = playlist => {
    this.setState({ playlist: playlist });
  };

  addToPlaylist = (tours, tourId) => {
    let thisTour = tours.filter(t => t._meta.id === tourId);
    let { playlist } = this.state;
    if (playlist && playlist.filter(list => list._meta.id === tourId).length === 0) {
      let playlistWithTour = [...playlist, ...thisTour];
      this.setState({ playlist: playlistWithTour });
    }
  };

  removeFromPlaylist = (tours, tourId) => {
    let { playlist } = this.state;
    let playlistWithoutTour = playlist.filter(af => af._meta.id !== tourId);
    this.setState({ playlist: playlistWithoutTour });
  };

  activatePlaylistFeature = () => {
    if (this.state.showAddToPlaylistButtons) {
      this.setState({
        showAddToPlaylistButtons: false
      });
    } else {
      this.setState({
        showAddToPlaylistButtons: true
      });
    }
  };
  pauseAndRestartAllVideos = () => {
    let allVideos = document.querySelectorAll('video');
    allVideos.forEach(function(video) {
      var playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            video.pause();
            video.currentTime = 0;
            setTimeout(() => video.play(), 1000);
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
      } else {
        return null;
      }
    });
  };
  toggleOrgTypes = (orgID, event) => {
    let { activeFilters } = this.state;
    this.setState({
      activeFilters: []
    });
    let activeFiltersContainFilter = activeFilters && activeFilters.length && activeFilters.includes(orgID);
    let activeFiltersWithFilter = [orgID];
    if (orgID === null) {
      this.setState({ activeFilters: [] });
    } else {
      this.setState({ activeFilters: activeFiltersWithFilter });
    }
    // if user clicks span, adjust the parent element
    let tabParent = event.target.classList.contains('tab-text') ? event.target.parentElement : event.target;
    let allTabs = document.querySelectorAll('.react-tabs__tab');
    let tabListUL = document.querySelector('.react-tabs__tab-list');

    allTabs.forEach(function(tab) {
      if (tab.classList.contains('react-tabs__tab--selected')) {
        tab.classList.remove('react-tabs__tab--selected');
        tab.setAttribute('aria-selected', 'false');
      }
    });
    tabParent.classList.add('react-tabs__tab--selected');
    tabParent.setAttribute('aria-selected', 'true');
  };

  toggleImageModal = () => {
    let { showImageModal, showPlaylist } = this.state;
    if (showPlaylist) {
      this.setState({
        showPlaylist: false
      });
    }
    if (showImageModal) {
      this.setState({
        showImageModal: false
      });
    } else {
      this.setState({
        showImageModal: true
      });
    }
  };

  getCurrentSlideImage = step => {
    if (step !== undefined) {
      let stepImage = '';
      let stepImageAlt = '';
      let { currentStepImageSrc, currentStepImageAlt, currentStepImageMobile } = this.state;
      if (step.ui_screen !== null) {
        stepImage = step.ui_screen.url;
        stepImageAlt = step.ui_screen.alt;
      } else if (step.ui_screen_mobile !== null) {
        stepImage = step.ui_screen_mobile.url;
        stepImageAlt = step.ui_screen_mobile.alt;
      }

      if (stepImage !== '') {
        if (currentStepImageSrc === '' || currentStepImageSrc !== stepImage) {
          this.setState({
            currentStepImageSrc: stepImage,
            currentStepImageAlt: stepImageAlt
          });
        }
        if (step.ui_screen !== null) {
          this.setState({
            currentStepImageMobile: false
          });
        }
        if (step.ui_screen_mobile !== null && currentStepImageMobile === false) {
          this.setState({
            currentStepImageMobile: true
          });
        }
      } else {
        return false;
      }
    }
  };

  render() {
    const { children } = this.props;
    const {
      activeFilters,
      playlist,
      sortMethod,
      sortOptions,
      showSort,
      showFilters,
      showPlaylist,
      currentTourTitle,
      currentSlide,
      showTimeIntervals,
      autoPlayIntervals,
      defaultTimeInterval,
      showPlayButton,
      languages,
      timerInitiated,
      slider,
      settings,
      filteredTours,
      languageVisible,
      presentationMode,
      hideHeader,
      showAddToPlaylistButtons,
      showImageModal,
      currentStepImageSrc,
      currentStepImageAlt,
      currentStepImageMobile
    } = this.state;

    return (
      <AppContext.Provider
        value={{
          activeFilters,
          playlist,
          sortMethod,
          sortOptions,
          showSort,
          showFilters,
          showPlaylist,
          currentTourTitle,
          currentSlide,
          showTimeIntervals,
          autoPlayIntervals,
          defaultTimeInterval,
          showPlayButton,
          languages,
          timerInitiated,
          slider,
          settings,
          languageVisible,
          filteredTours,
          presentationMode,
          hideHeader,
          showAddToPlaylistButtons,
          showImageModal,
          currentStepImageSrc,
          currentStepImageAlt,
          currentStepImageMobile,
          toggleShowSort: this.toggleShowSort,
          toggleShowFilters: this.toggleShowFilters,
          toggleFilter: this.toggleFilter,
          clearFilters: this.clearFilters,
          addToPlaylist: this.addToPlaylist,
          updatePlaylist: this.updatePlaylist,
          removeFromPlaylist: this.removeFromPlaylist,
          setSortMethod: this.setSortMethod,
          closeOverlay: this.closeOverlay,
          toggleMobileSortAndFilter: this.toggleMobileSortAndFilter,
          toggleDraggablePlaylist: this.toggleDraggablePlaylist,
          updateTourPlaylist: this.updateTourPlaylist,
          setCurrentTourTitle: this.setCurrentTourTitle,
          checkCurrentTourTitle: this.checkCurrentTourTitle,
          getCurrentSlide: this.getCurrentSlide,
          updateDefaultTimeInterval: this.updateDefaultTimeInterval,
          toggleTimerIntervals: this.toggleTimerIntervals,
          togglePlayPauseButton: this.togglePlayPauseButton,
          checkSlideIndexNext: this.checkSlideIndexNext,
          checkSlideIndexPrev: this.checkSlideIndexPrev,
          setShowPlayButton: this.setShowPlayButton,
          handleKeyPress: this.handleKeyPress,
          updateLanguage: this.updateLanguage,
          clearPlaylistFromTour: this.clearPlaylistFromTour,
          updateSlickSettings: this.updateSlickSettings,
          toggleLanguageVisible: this.toggleLanguageVisible,
          addMobileClass: this.addMobileClass,
          removeMobileClass: this.removeMobileClass,
          stopTourAutoPlay: this.stopTourAutoPlay,
          stopPresentationMode: this.stopPresentationMode,
          closeOpenDialogs: this.closeOpenDialogs,
          goToTour: this.goToTour,
          activatePlaylistFeature: this.activatePlaylistFeature,
          pauseAndRestartAllVideos: this.pauseAndRestartAllVideos,
          toggleOrgTypes: this.toggleOrgTypes,
          startPresentationMode: this.startPresentationMode,
          stopPresentationMode: this.stopPresentationMode,
          toggleImageModal: this.toggleImageModal,
          getCurrentSlideImage: this.getCurrentSlideImage
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export { AppProvider };
