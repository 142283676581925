import React from 'react';

export const defaultState = {
  activeFilters: [],
  showPlaylist: false,
  showFilters: false,
  showSort: false,
  sortOptions: ['most_popular', 'a_z', 'newest'],
  sortMethod: 'a_z',
  autoPlayIntervals: ['10000', '15000', '20000'],
  defaultTimeInterval: 10000,
  timerInitiated: false,
  showPlayButton: true,
  presentationMode: false,
  settings: undefined,
  showTimeIntervals: false,
  hideHeader: false,
  playSpeed: 5,
  playlist: [],
  languageVisible: false,
  slider: React.createRef(),
  showAddToPlaylistButtons: false,
  showImageModal: false,
  currentStepImageSrc: '',
  currentStepImageAlt: '',
  currentStepImageMobile: false
};

const AppContext = React.createContext(defaultState);

export default AppContext;
